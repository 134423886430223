import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import canopyUrls from "canopy-urls!sofe";

const appendSignerId = (signerId) => (signerId ? `?signer_id=${signerId}` : "");

export function createKbaInstance(esign_doc_id, person, signerId = null) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/docs/esign-docs/${esign_doc_id}/kba${appendSignerId(signerId)}`,
    {
      method: "POST",
      body: { person },
    }
  );
}

export function submitKbaResponses(esign_doc_id, person_id, questions_id, answers, signerId = null) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/docs/esign-docs/${esign_doc_id}/kba/${person_id}/questions/${questions_id}/answers${appendSignerId(
      signerId
    )}`,
    {
      method: "POST",
      body: { answers },
    }
  ).pipe(pluck("answers_accepted"));
}

export function recordPhysicalSignature(esignDocId, fileId, signerId = null) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/docs/esign-docs/${esignDocId}/esign-phys-sign${appendSignerId(signerId)}`,
    {
      method: "POST",
      body: {
        phys_sign_data: {
          file_id: fileId,
        },
      },
    }
  );
}

export function getSignedKba(esignDocId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/docs/esign-docs/${esignDocId}/completed-download-url`);
}

export function getKbaCredits() {
  // KBA needs to be upper-cased if we hit directory-service directly. If it is a file-service service, then use lowercase
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/per-use-licenses/KBA`);
}
